import { NgModule } from '@angular/core';
import { ShowIfOnlineDirective } from './show-if-online.directive';
import { ScrollHideDirective } from './hide-scroll.directive';
import { StopClickEventPropagationDirective } from './stop-click-event-propagation.directive';
import { PrintDirective } from './print.directive';
import { ClickToCopyDirective } from './click-to-copy.directive';
import { CurrencyMaskDirective } from './currency-mask.directive';
import { CurrencyMaskV2Directive } from './currency-mask-v2.directive';
import { SaveLockButtonDirective } from './save-lock-button.directive';
import { DragDropDirective } from './drag-drop.directive';

@NgModule({
  declarations: [
    ClickToCopyDirective,
    CurrencyMaskDirective,
    CurrencyMaskV2Directive,
    PrintDirective,
    SaveLockButtonDirective,
    ScrollHideDirective,
    ShowIfOnlineDirective,
    StopClickEventPropagationDirective,
    DragDropDirective,
  ],
  exports: [
    ClickToCopyDirective,
    CurrencyMaskDirective,
    CurrencyMaskV2Directive,
    PrintDirective,
    SaveLockButtonDirective,
    ScrollHideDirective,
    ShowIfOnlineDirective,
    StopClickEventPropagationDirective,
    DragDropDirective,
  ],
})
export class DirectivesModule {}
