export class DataKlinik {
  constructor(
    public id: number,
    public nama?: string,
    public alias?: string,
    public logo?: string,
    public telepon1?: string,
    public telepon2?: string,
    public alamat?: string,
    public email?: string,
    public latitude?: string,
    public longitude?: string,
    public lokasi?: string,
    public noRekamMedis?: string,
    public typePrice?: string,
    public bayarPendaftaranAwal?: number,
    public idGroupDataKlinik?: number,
    public asesmenNyeri?: number,
    public filterTarifInstallasi?: number,
    public sharingPasien?: number,
    public pemotonganStock?: number,
    public noGigiTindakan?: number,
    public cicilan?: number,
    public timeZone?: string,
    public url?: string,
    public instagram?: string,
    public kodePpkBpjs?: string,
    public statusBayarPendaftaran?: number,
    public _embedded?: {
      tipeInstitusi: any;
    }
  ) {}
}

export class DataKlinikV2 {
  constructor(
    public id: number,
    public kodeDataKlinik?: string,
    public namaOwner?: string,
    public nama?: string,
    public alias?: string,
    public url?: string,
    public url2?: string,
    public lokasi?: string,
    public logo?: string,
    public telepon1?: string,
    public telepon2?: string,
    public alamat?: string,
    public email?: string,
    public instagram?: string,
    public twitter?: string,
    public facebook?: string,
    public linkedin?: string,
    public website?: string,
    public latitude?: string,
    public longitude?: string,
    public noRekamMedis?: string,
    public typePrice?: string,
    public idGroupDataKlinik?: number,
    public sharingPasien?: number,
    public rangeVoid?: number,
    public deskripsi?: string,
    public timeZone?: string,
    public typeFarmasi?: string,
    public strukPos?: string,
    public strukKasir?: string,
    public metodeDiskon?: number,
    public hargaSatuan?: number,
    public diskonPos?: number,
    public anamnesis?: number,
    public pemeriksaanPenunjang?: number,
    public masalahMedis?: number,
    public tindakanParameter?: number,
    public cicilan?: number,
    public asesmenNyeri?: number,
    public filterTarifInstalasi?: number,
    public icd10?: number,
    public icd10Gigi?: number,
    public icd9Cm?: number,
    public tindakanMinmed?: number,
    public delivery?: number,
    public noGigiTindakan?: number,
    public questionTindakan?: number,
    public pemeriksaanVital?: number,
    public kodePpkBpjs?: string,
    public bayarPendaftaranAwal?: number,
    public pemotonganStok?: number,
    public idKategoriKlinik?: number,
    public urlBpjs?: string,
    public statusInputTindakan?: number,
    public statusInputResep?: number,
    public filterBarangNasabah?: number,
    public statusBpjs?: number,
    public statusHargaResep?: number,
    public kunciResepKlinik?: number,
    public statusInputBiayaAwal?: number,
    public statusInputTarifTindakan?: number,
    public statusBayarPendaftaran?: number,
    public ttsSpeakingRate?: string,
    public statusQtyTindakan?: number,
    public statusAppointmentMobile?: number,
    public consIdBpjs?: string,
    public passwordBpjs?: string,
    public userKeyBpjs?: string,
    public statusHapusKunjungan?: number,
    public regional?: number,
    public tipeFaskes?: string,
    public kelasFaskes?: string,
    public nominalKlaimInacbg?: number,
    public totalJamKerjaAbsen?: number,
    public statusTuslah?: number,
    public statusRadiusInputCppt?: number,
    public foto1?: string,
    public sharingStock?: number,
    public statusStockHabisResep?: number,
    public statusStockRiwayatResep?: number,
    public bannerImage?: string
  ) {}
}

export interface DataKlinikV3 {
  id: number;
  nama: string;
  alamat: string;
  email: string;
  foto1: string;
  foto2: string;
  latitude: string;
  longitude: string;
  linkedin: string;
  logo: string;
  noTelp: string;
  tanggalBerdiri: string;
}
