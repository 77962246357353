import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCommonComponent } from './content-common/content-common.component';
import { DetailModalCommonComponent } from './detail-modal-common/detail-modal-common.component';
import { HeaderCommonComponent } from './header-common/header-common.component';
import { ItemBoxCommonComponent } from './item-box-common/item-box-common.component';
import { ItemCardCommonComponent } from './item-card-common/item-card-common.component';
import { ItemCardCommonV2Component } from './item-card-common-v2/item-card-common-v2.component';
import { AlertModalCommonComponent } from './_modals/alert-modal-common/alert-modal-common.component';
import { MenuModalCommonComponent } from './_modals/menu-modal-common/menu-modal-common.component';
import { IonicModule } from '@ionic/angular';
import { SegmentCommonComponent } from './segment-common/segment-common.component';
import { ItemCardChipComponent } from './item-card-common/item-card-chip/item-card-chip.component';
import { RangeDatepickerCommonComponent } from './range-datepicker-common/range-datepicker-common.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { SortModalCommonComponent } from './sort-modal-common/sort-modal-common.component';
import { SelectableInputCommonComponent } from './selectable-input-common/selectable-input-common.component';
import { SelectableModalCommonComponent } from './_modals/selectable-modal-common/selectable-modal-common.component';

@NgModule({
  declarations: [
    AlertModalCommonComponent,
    ContentCommonComponent,
    DetailModalCommonComponent,
    RangeDatepickerCommonComponent,
    HeaderCommonComponent,
    ItemBoxCommonComponent,
    ItemCardChipComponent,
    ItemCardCommonComponent,
    ItemCardCommonV2Component,
    MenuModalCommonComponent,
    SegmentCommonComponent,
    SortModalCommonComponent,
    SelectableInputCommonComponent,
    SelectableModalCommonComponent,
  ],
  imports: [CommonModule, IonicModule, MatDatepickerModule, MatNativeDateModule, MatCardModule],
  exports: [
    AlertModalCommonComponent,
    ContentCommonComponent,
    DetailModalCommonComponent,
    RangeDatepickerCommonComponent,
    HeaderCommonComponent,
    ItemBoxCommonComponent,
    ItemCardChipComponent,
    ItemCardCommonComponent,
    ItemCardCommonV2Component,
    MenuModalCommonComponent,
    SegmentCommonComponent,
    SelectableInputCommonComponent,
  ],
})
export class CommonUiModule {}
