import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BuildForm } from 'app/models/formulir/build-form.model';
import { environment } from 'environments/environment';
import { BuildFormAdapter } from 'app/services/adapters/formulir/build-form.adapter.service';
import { ClinicEnvironmentService } from '../../clinic-environment.service';

@Injectable({
  providedIn: 'root',
})
export class BuildFormApiService {
  constructor(
    private adapter: BuildFormAdapter,
    private clinicEnv: ClinicEnvironmentService,
    private http: HttpClient
  ) {}

  public getAll(): Observable<BuildForm[]> {
    return this.http.get(`${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId}/build-form`).pipe(
      catchError((err) => throwError(err)),
      map((data: any) => {
        if (!data) return [];
        return (data.responses.buildForm as any[]).map((item: unknown) => this.adapter.adapt(item));
      })
    );
  }

  public getById(id: number): Observable<BuildForm> {
    return this.http.get(`${environment.urlV2}/build-form/${id}`).pipe(
      map((data: any) => {
        if (!data) return null;
        return (data.responses.buildForm as any[]).map((item: unknown) => this.adapter.adapt(item))[0];
      }),
      catchError((err) => throwError(err))
    );
  }

  public getFromSourceTableById(idBuildForm: number, idItem: number) {
    return this.http
      .get(`${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId}/build-form/${idBuildForm}/table-data/${idItem}`)
      .pipe(
        catchError((err) => throwError(err)),
        map((data: any) => {
          if (!data) return null;
          return data.responses.tableData;
        })
      );
  }

  public getFromSourceTable(idBuildForm: number, dateRange?: { start: string; end: string }, installasi?: number) {
    const params = {
      ...dateRange,
    };
    if (installasi) params['installasi'] = String(installasi);
    const dateParams = new URLSearchParams(params).toString();
    return this.http
      .get(
        `${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId}/build-form/${idBuildForm}/table-data?${dateParams}`
      )
      .pipe(
        map((data: any) => {
          if (!data) return [];
          return data.responses.tableData as any[];
        }),
        catchError((err) => throwError(err))
      );
  }

  public insertToSourceTable(idBuildForm: number, data: any) {
    return this.http
      .post(`${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId}/build-form/${idBuildForm}/table-data`, data)
      .pipe(catchError((err) => throwError(err)));
  }

  public updateDataSourceTable(idBuildForm: number, idItem: number, data: any) {
    return this.http
      .patch(
        `${environment.urlV2}/data-klinik/${this.clinicEnv.clinicId}/build-form/${idBuildForm}/table-data/${idItem}`,
        data
      )
      .pipe(catchError((err) => throwError(err)));
  }
}
