import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError, forkJoin } from 'rxjs';

import { Device } from '@ionic-native/device/ngx';

import { environment } from '../../environments/environment';
import { Auth } from '../models/auth.model';
import { StorageService } from './storage/storage.service';
import { storageDict } from './storage/storage-dict';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { ClinicEnvironmentService } from './clinic-environment.service';
import { DataKlinikService } from './data/data-klinik.data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = environment.url;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // State to check if the user is logged in or not
  public authState$ = new BehaviorSubject<boolean>(false);

  public roleId: number;
  public idLogStatus: any;
  public idPasien: any;
  public klinik: any;

  constructor(
    private clinicEnvService: ClinicEnvironmentService,
    private dataKlinikService: DataKlinikService,
    private http: HttpClient,
    private device: Device,
    private platform: Platform,
    private storage: StorageService
  ) {}

  signIn(credentials: { username: string; password: string; fcmToken: string }): Observable<Auth> {
    const username = credentials.username;
    const password = credentials.password;
    const source = 'mobile-klinik';
    const deviceInfo = this.getDeviceUuid();
    const fcmToken = credentials.fcmToken;

    const loginUrl = `${environment.urlAuth}/v1/authenticate`;

    return this.getKlinik(this.clinicEnvService.clinicId).pipe(
      switchMap((klinik) => {
        const postData = {
          username: username,
          password: password,
          source: source,
          deviceInfo: deviceInfo,
          fcmToken: fcmToken,
          dataKlinikUrl: klinik.url2,
        };
        return this.http.post<Auth>(loginUrl, postData);
      })
    );
  }

  signInQr(data: any, code: string): Observable<any> {
    const url = `${environment.urlAuth}/v1/verify?code=${code}&source=mobile-klinik`;
    return this.http.post(url, data, this.httpOptions).pipe(catchError((err) => throwError(err)));
  }

  signOut(): Observable<any> {
    const deviceInfo = this.getDeviceUuid();

    return this.storage.getItem(storageDict.refreshToken).pipe(
      switchMap((id: number) => {
        const options = { ...this.httpOptions };
        return this.http.post(`${environment.urlV2}/klinik/sign-out`, { refreshToken: id, deviceInfo }, options);
      }),
      catchError((err) => throwError(err))
    );
  }

  getNewToken(): Observable<any> {
    return forkJoin([this.storage.getItem(storageDict.idLogin), this.storage.getItem(storageDict.refreshToken)]).pipe(
      switchMap((res: [number, string]) =>
        this.http.post(
          `${environment.urlAuth}/v1/refresh`,
          {
            id: res[0],
            refreshToken: res[1],
          },
          this.httpOptions
        )
      ),
      catchError((err) => throwError(err))
    );
  }

  // Change authentication state
  changeAuthState(val: boolean): void {
    this.authState$.next(val);
    this.clinicEnvService.authState$.next(val);
  }

  // Get authentication state
  getAuthState(): Observable<boolean> {
    return this.authState$.asObservable();
  }

  /* ---------- Helper methods ----------- */
  public getKlinik(idDataKlinik: number) {
    return this.dataKlinikService.getAllDataKlinikV2(idDataKlinik).pipe(
      tap((res) => {
        this.klinik = res;
      })
    );
  }

  getDeviceUuid(): string {
    const userAgent = window.navigator.userAgent;
    if (this.platform.is('capacitor')) {
      return this.device.uuid ? this.device.uuid : userAgent;
    }
    return userAgent;
  }
}
