import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DownloadPhotoApiService } from '../api/download-photo.api.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadPhotoDataService {
  constructor(private downloadPhotoApiService: DownloadPhotoApiService) {}

  public getPhoto(url: string): Observable<any> {
    return this.downloadPhotoApiService.getPhoto(url);
  }
}
