import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { StorageService } from 'app/services/storage/storage.service';
import { storageDict } from 'app/services/storage/storage-dict';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private storageService: StorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('canActivate');
    // return this.authService.getAuthState().pipe(
    //     tap((res) => console.log('authState', res)),
    //     map(loggedIn => {
    //         if (!loggedIn) {
    //             this.router.navigateByUrl('loginBackground', { replaceUrl: true, skipLocationChange: true });
    //             return false;
    //         }
    //         return true;
    //     })
    // );

    // Check if user is logged in
    return this.storageService.getItem(storageDict.refreshToken).pipe(
      // tap((res) => console.log('loggedIn', res)),
      map((res) => {
        if (!res) {
          this.router.navigateByUrl('loginBackground', {
            replaceUrl: true,
            skipLocationChange: true,
          });
          this.authService.changeAuthState(false);
          return false;
        }
        this.authService.changeAuthState(true);
        return true;
      })
    );
  }
}
