import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { DataKlinik, DataKlinikV2, DataKlinikV3 } from '../../models/data-klinik.model';

@Injectable({
  providedIn: 'root',
})
export class DataKlinikAdapter implements Adapter<DataKlinik> {
  public adapt(item: any): DataKlinik {
    return new DataKlinik(
      item.id,
      item.nama,
      item.alias,
      item.logo,
      item.telepon1,
      item.telepon2,
      item.alamat,
      item.email,
      item.latitude,
      item.longitude,
      item.lokasi,
      item.noRekamMedis,
      item.typePrice,
      item.bayarPendaftaranAwal,
      item.idGroupDataKlinik,
      item.asesmenNyeri,
      item.filterTarifInstallasi,
      item.sharingPasien,
      item.pemotonganStock,
      item.noGigiTindakan,
      item.cicilan,
      item.timeZone,
      item.url2,
      item.instagram,
      item.kodePpkBpjs,
      item.statusBayarPendaftaran,
      item._embedded
    );
  }

  public adaptV2(item: any): DataKlinikV2 {
    return new DataKlinikV2(
      item.idDataKlinik,
      item.kodeDataKlinik,
      item.namaOwner,
      item.nama,
      item.namaAlias,
      item.url,
      item.url2,
      item.lokasi,
      item.logo,
      item.noTelp,
      item.noTelp2,
      item.alamat,
      item.email,
      item.instagram,
      item.twitter,
      item.facebook,
      item.linkedin,
      item.website,
      item.latitude,
      item.longitude,
      item.noRekamMedis,
      item.typePrice,
      item.idGroupDataKlinik,
      item.sharingPasien,
      item.rangeVoid,
      item.deskripsi,
      item.timeZone,
      item.typeFarmasi,
      item.strukPos,
      item.strukKasir,
      item.metodeDiskon,
      item.hargaSatuan,
      item.diskonPos,
      item.anamnesis,
      item.pemeriksaanPenunjang,
      item.masalahMedis,
      item.tindakanParameter,
      item.cicilan,
      item.asesmenNyeri,
      item.filterTarifInstallasi,
      item.icd10,
      item.icd10Gigi,
      item.icd9Cm,
      item.tindakanMinmed,
      item.delivery,
      item.noGigiTindakan,
      item.questionTindakan,
      item.pemeriksaanVital,
      item.kodePpkBpjs,
      item.bayarPendaftaranAwal,
      item.pemotonganStok,
      item.idKategoriKlinik,
      item.urlBpjs,
      item.statusInputTindakan,
      item.statusInputResep,
      item.filterBarangNasabah,
      item.statusBpjs,
      item.statusHargaResep,
      item.kunciResepKlinik,
      item.statusInputBiayaAwal,
      item.statusInputTarifTindakan,
      item.statusBayarPendaftaran,
      item.ttsSpeakingRate,
      item.statusQtyTindakan,
      item.statusAppointmentMobile,
      item.consIdBpjs,
      item.passwordBpjs,
      item.userKeyBpjs,
      item.statusHapusKunjungan,
      item.regional,
      item.tipeFaskes,
      item.kelasFaskes,
      item.nominalKlaimInacbg,
      item.totalJamKerjaAbsen,
      item.statusTuslah,
      item.statusRadiusInputCppt,
      item.foto1,
      item.sharingStock,
      item.statusStockHabisResep,
      item.statusStockRiwayatResep,
      item.groupBanner
    );
  }

  public adaptV3(item: any): DataKlinikV3 {
    return {
      id: item.id,
      alamat: item.ALAMAT,
      email: item.EMAIL,
      foto1: item.FOTO1,
      foto2: item.FOTO2,
      latitude: item.LATITUDE,
      linkedin: item.LINKEDIN,
      logo: item.LOGO,
      longitude: item.LONGITUDE,
      nama: item.NAMA,
      noTelp: item.NO_TELP,
      tanggalBerdiri: item.TANGGAL_BERDIRI,
    };
  }
}
