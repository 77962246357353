import { Injectable, NgZone } from '@angular/core';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { DownloadPhotoDataService } from './data/download-photo.data.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StorageService } from './storage/storage.service';
import { Observable, of } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class ImageDownloaderService {
  constructor(
    private downloadPhotoDataService: DownloadPhotoDataService,
    private sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  public createImageFromBlob(image: Blob) {
    const unsafeImageUrl = URL.createObjectURL(image);
    return this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  public blobToArrayBuffer(blob: Blob): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imgBlob = new Blob([reader.result], {
          type: blob.type,
        });
        resolve(imgBlob);
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  public fetchImage(url: string, saveToStorage = true): Observable<SafeUrl> {
    const fileName = url.split('/').pop();
    return this.storageService.getItem(`blobs`).pipe(
      concatMap((res) => {
        // ? Blob already exist in storage.
        if (res && res[fileName]) {
          return of(res[fileName]);
        }

        return this.downloadPhotoDataService.getPhoto(url).pipe(
          tap((photoBlob) => {
            if (saveToStorage && photoBlob.size > 0) {
              const temp = res || {};
              temp[fileName] = photoBlob; //FIXME: replaces value by other stream when called once at a time.
              this.storageService.setItem(`blobs`, temp).toPromise();
            }
          }),
          catchError((err) => of(null))
        );
      }),
      map((blob) => {
        if (blob === null || blob.size === 0) {
          return null;
        }
        return this.createImageFromBlob(blob);
      })
    );
  }
}
