import { Injectable } from '@angular/core';
import { BuildFormApiService } from 'app/services/api/formulir/build-form.api.service';

@Injectable({
  providedIn: 'root',
})
export class BuildFormDataService {
  constructor(private buildFormApiService: BuildFormApiService) {}

  public getAll() {
    return this.buildFormApiService.getAll();
  }

  public getById(id: number) {
    return this.buildFormApiService.getById(id);
  }

  public getFromSourceTableById(idBuildForm: number, idItem: number) {
    return this.buildFormApiService.getFromSourceTableById(idBuildForm, idItem);
  }

  public getFromSourceTable(idBuildForm: number, dateRange?: { start: string; end: string }, installasi?: number) {
    return this.buildFormApiService.getFromSourceTable(idBuildForm, dateRange, installasi);
  }

  public insertToSourceTable(idBuildForm: number, data: any) {
    return this.buildFormApiService.insertToSourceTable(idBuildForm, data);
  }

  public updateDataSourceTable(idBuildForm: number, idItem: number, data: any) {
    return this.buildFormApiService.updateDataSourceTable(idBuildForm, idItem, data);
  }
}
