import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { HeaderComponent } from '../header/header.component';
import { ConnectionErrorComponent } from '../connection-error/connection-error.component';
import { NoDataComponent } from '../no-data/no-data.component';
import { AddItemsModalComponent } from '../add-items-modal/add-items-modal.component';
import { AddActionsModalComponent } from '../add-actions-modal/add-actions-modal.component';
import { SaleListComponent } from '../sale-list/sale-list.component';
import { CouponListModalComponent } from '../coupon-list-modal/coupon-list-modal.component';
import { CashInputModalComponent } from '../cash-input-modal/cash-input-modal.component';
import { PaymentStatusesModalComponent } from '../payment-statuses-modal/payment-statuses-modal.component';
import { FullPageLoadingComponent } from '../full-page-loading/full-page-loading.component';
import { PosItemBoxComponent } from '../pos-item-box/pos-item-box.component';
import { PosItemComponent } from '../pos-item/pos-item.component';
import { SaleListItemComponent } from '../sale-list-item/sale-list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PosFooterComponent } from '../pos-footer/pos-footer.component';
import { PpnEditModalComponent } from '../ppn-edit-modal/ppn-edit-modal.component';
import { PaymentInputHeaderComponent } from '../payment-input-header/payment-input-header.component';
import { PaymentStatusItemComponent } from '../payment-status-item/payment-status-item.component';
import { PaymentReceiptComponent } from '../payment-receipt/payment-receipt.component';
import { RouterModule } from '@angular/router';
import { CouponListItemComponent } from '../coupon-list-item/coupon-list-item.component';
import { CouponListItemDetailComponent } from '../coupon-list-item-detail/coupon-list-item-detail.component';
import { DirectivesModule } from '../../directives/directive.module';
import { CashInputComponent } from '../cash-input/cash-input.component';
import { SearchableInputComponent } from '../searchable-input/searchable-input.component';
import { PosNewPatientModalComponent } from '../pos-new-patient-modal/pos-new-patient-modal.component';
import { CashierQueueItemComponent } from '../cashier-queue-item/cashier-queue-item.component';
import { CashierQueueFilterModalComponent } from '../cashier-queue-filter-modal/cashier-queue-filter-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { IonicSelectableModule } from 'ionic-selectable';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { PaymentAcceptanceComponent } from '../payment-acceptance/payment-acceptance.component';
import { ManageItemFilterModalComponent } from '../manage-item-filter-modal/manage-item-filter-modal.component';
import { ManageTarifTindakanFilterModalComponent } from '../manage-tarif-tindakan-filter-modal/manage-tarif-tindakan-filter-modal.component';
import { ManageServiceFilterModalComponent } from '../manage-service-filter-modal/manage-service-filter-modal.component';
import { NoDataV2Component } from '../no-data-v2/no-data-v2.component';
import { PaidPaymentItemComponent } from '../paid-payment-item/paid-payment-item.component';
import { AppointmentCardComponent } from '../appointment-card/appointment-card.component';
import { AppointmentActionModalComponent } from '../appointment-action-modal/appointment-action-modal.component';
import { InlineLoadingComponent } from '../inline-loading/inline-loading.component';
import { AppointmentChatMessageComponent } from '../appointment-chat-message/appointment-chat-message.component';
import { DashboardAppointmentsComponent } from '../dashboard-appointments/dashboard-appointments.component';
import { EdcInputComponent } from '../edc-input/edc-input.component';
import { ProcessingEdcModalComponent } from '../processing-edc-modal/processing-edc-modal.component';
import { PreviewPhotoModalComponent } from '../preview-photo-modal/preview-photo-modal.component';
import { SendPhotoModalComponent } from '../send-photo-modal/send-photo-modal.component';
import { ForegroundNotificationService } from '../../services/foreground-notification.service';
import { PosPhoneModalComponent } from '../pos-phone-modal/pos-phone-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { PatientFilterModalComponent } from '../patient-filter-modal/patient-filter-modal.component';
import { DashboardAppointmentsOfflineComponent } from '../dashboard-appointments-offline/dashboard-appointments-offline.component';
import { BniEcollectionInputComponent } from '../bni-ecollection-input/bni-ecollection-input.component';
import { VirtualAccountNumberModalComponent } from '../virtual-account-number-modal/virtual-account-number-modal.component';
import { PindahDokterModalComponent } from '../pindah-dokter-modal/pindah-dokter-modal.component';
import { VisitHistoryModalComponent } from '../visit-history-modal/visit-history-modal.component';
import { PrintComponent } from '../print/print.component';
import { ManageCetakKasirFilterModalComponent } from '../manage-cetak-kasir-filter-modal/manage-cetak-kasir-filter-modal.component';
import { ManageDoctorScheduleFilterModalComponent } from '../manage-doctor-schedule-filter-modal/manage-doctor-schedule-filter-modal.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { StackedBarChartComponent } from '../stacked-bar-chart/stacked-bar-chart.component';
import { NgCalendarModule } from 'ionic2-calendar';
import { AddHolidayScheduleModalComponent } from '../add-holiday-schedule-modal/add-holiday-schedule-modal.component';
import { AddKeywordPgComponent } from '../add-keyword-pg/add-keyword-pg.component';
import { AddKeywordAdimeComponent } from '../add-keyword-adime/add-keyword-adime.component';
import { DeleteKeywordPgComponent } from '../delete-keyword-pg/delete-keyword-pg.component';
import { AbsentHistoryModalComponent } from '../absent-history-modal/absent-history-modal.component';
import { SearchableSelectComponent } from '../searchable-select/searchable-select.component';
import { SearchableSelectModalComponent } from '../searchable-select-modal/searchable-select-modal.component';
import { KeywordModalComponent } from '../keyword-modal/keyword-modal.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AssesmentModalComponent } from '../assesment-modal/assesment-modal.component';
import { PengeluaranGudangCardComponent } from '../pengeluaran-gudang-card/pengeluaran-gudang-card.component';
import { VideoCallPopoverComponent } from '../video-call-popover/video-call-popover.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { KalenderJadwalPasienFilterModalComponent } from '../kalender-jadwal-pasien-filter-modal/kalender-jadwal-pasien-filter-modal.component';
import { DetailResepDigitalCardComponent } from '../detail-resep-digital-card/detail-resep-digital-card.component';
import { EditCaraPemakaianModalComponent } from '../edit-cara-pemakaian-modal/edit-cara-pemakaian-modal.component';
import { EditResepItemModalComponent } from '../edit-resep-item-modal/edit-resep-item-modal.component';
import { TelaahObatModalComponent } from '../telaah-obat-modal/telaah-obat-modal.component';
import { VerifikasiResepModalComponent } from '../verifikasi-resep-modal/verifikasi-resep-modal.component';
import { SearchKaryawanComponent } from '../search-karyawan/search-karyawan.component';
import { FilterSuratModalComponent } from '../filter-surat-modal/filter-surat-modal.component';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { FormPemeriksaanLaboratoriumComponent } from '../form-pemeriksaan-laboratorium/form-pemeriksaan-laboratorium.component';
import { FormRujukRadiologiComponent } from '../form-rujuk-radiologi/form-rujuk-radiologi.component';
import { FormAssesmentMedisComponent } from '../form-assesment-medis/form-assesment-medis.component';
import { FormPemeriksaanVitalComponent } from '../form-pemeriksaan-vital/form-pemeriksaan-vital.component';
import { FormIcdTenComponent } from '../form-icd-ten/form-icd-ten.component';
import { MaterialModule } from 'app/material/material.module';
import { FormIcdNineComponent } from '../form-icd-nine/form-icd-nine.component';
import { FormRujukRawatComponent } from '../form-rujuk-rawat/form-rujuk-rawat.component';
import { FormCpptComponent } from '../form-cppt/form-cppt.component';
import { FormTindakanMedisComponent } from '../form-tindakan-medis/form-tindakan-medis.component';
import { FormAlergiComponent } from '../form-alergi/form-alergi.component';
import { FormBahanMedisComponent } from '../form-bahan-medis/form-bahan-medis.component';
import { FormOdontogramComponent } from '../form-odontogram/form-odontogram.component';
import { DoctorChatCreateModalComponent } from '../doctor-chat-create-modal/doctor-chat-create-modal.component';
import { TagihanPasienCardComponent } from '../tagihan-pasien-card/tagihan-pasien-card.component';
import { AddKeywordAnamnesisComponent } from '../add-keyword-anamnesis/add-keyword-anamnesis.component';
import { ChangeDateModalComponent } from '../change-date-modal/change-date-modal.component';
import { FilterResepDigitalRanapModalComponent } from '../page-farmasi/filter-resep-digital-ranap-modal/filter-resep-digital-ranap-modal.component';
import { FilterAntrianRawatInapComponent } from '../page-farmasi/filter-antrian-rawat-inap/filter-antrian-rawat-inap.component';
import { FormTindakanRadiologiComponent } from '../form-tindakan-radiologi/form-tindakan-radiologi.component';
import { FormDataPemeriksaanComponent } from '../form-data-pemeriksaan/form-data-pemeriksaan.component';
import { FotoPasienComponent } from '../foto-pasien/foto-pasien.component';
import { FormTindakanLaboratoriumComponent } from '../form-tindakan-laboratorium/form-tindakan-laboratorium.component';
import { FormDataPemeriksaanLaboratoriumComponent } from '../form-data-pemeriksaan-laboratorium/form-data-pemeriksaan-laboratorium.component';
import { HistoryPemeriksaanLaboratoriumComponent } from '../history-pemeriksaan-laboratorium/history-pemeriksaan-laboratorium.component';
import { DateFilterModalComponent } from '../date-filter-modal/date-filter-modal.component';
import { FormRingkasanPulangComponent } from '../form-ringkasan-pulang/form-ringkasan-pulang.component';
import { UpdateInvoiceModalComponent } from '../update-invoice-modal/update-invoice-modal.component';
import { DashboardOperationScheduleComponent } from '../dashboard-operation-schedule/dashboard-operation-schedule.component';
import { OperationScheduleCardComponent } from '../operation-schedule-card/operation-schedule-card.component';
import { FormTindakanDiagnosaComponent } from '../form-tindakan-diagnosa/form-tindakan-diagnosa.component';
import { PhotoCardComponent } from 'app/components/photo-card/photo-card.component';
import { GetPhotoService } from 'app/services/get-photo.service';
import { UploadPhotoComponent } from '../upload-photo/upload-photo.component';
import { CommonUiModule } from '../_ui/common-ui.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FilterRangeDateModalComponent } from '../filter-range-date-modal/filter-range-date-modal.component';
import { DebitCreditInputComponent } from '../debit-credit-input/debit-credit-input.component';
import { InsuranceInputComponent } from '../insurance-input/insurance-input.component';
import { PrintResepComponent } from 'app/medis/antrian-resep-digital/components/print-resep/print-resep.component';
import { SaveResepComponent } from 'app/medis/antrian-resep-digital/components/save-resep/save-resep.component';
import { ToothComponent } from 'app/medis/drawing-odontogram/components/tooth/tooth.component';
import { TeethComponent } from 'app/medis/drawing-odontogram/components/teeth/teeth.component';
import { ToothInputModalComponent } from 'app/medis/drawing-odontogram/components/tooth-input-modal/tooth-input-modal.component';
import { PrintOdontogramComponent } from '../print-odontogram/print-odontogram.component';
import { PrintSuratSakitComponent } from '../print-surat-sakit/print-surat-sakit.component';
import { PrintSuratSehatComponent } from '../print-surat-sehat/print-surat-sehat.component';
import { FormDietKhususComponent } from '../form-diet-khusus/form-diet-khusus.component';
import { EmployeeInputComponent } from '../employee-input/employee-input.component';
import { OdontogramTableComponent } from 'app/trisakti/form-medis/odontogram-form/odontogram-table/odontogram-table.component';
import { DeleteKeywordPgV2Component } from 'app/trisakti/form-medis/cppt-form/delete-keyword-pg/delete-keyword-pg.component';
import { PrintRekamMedisComponent } from '../print-rekam-medis/print-rekam-medis.component';
import { AgmCoreModule } from '@agm/core';
import { InputFileComponent } from '../input-file/input-file.component';
import { DetailPaymentModalComponent } from 'app/pos-v2/components/detail-payment-modal/detail-payment-modal.component';
import { CashInputPaymentComponent } from 'app/pos-v2/components/payment-input/cash-input-payment/cash-input-payment.component';
import { CardInputPaymentComponent } from 'app/pos-v2/components/payment-input/card-input-payment/card-input-payment.component';
import { InsuranceInputPaymentComponent } from 'app/pos-v2/components/payment-input/insurance-input-payment/insurance-input-payment.component';
import { TransferInputPaymentComponent } from 'app/pos-v2/components/payment-input/transfer-input-payment/transfer-input-payment.component';
import { PegawaiInputPaymentComponent } from 'app/pos-v2/components/payment-input/pegawai-input-payment/pegawai-input-payment.component';
import { StatusPembayaranModalComponent } from 'app/pos-v2/components/status-pembayaran-modal/status-pembayaran-modal.component';
import { EwalletInputPaymentComponent } from 'app/pos-v2/components/payment-input/ewallet-input-payment/ewallet-input-payment.component';
import { EwalletModalComponent } from 'app/pos-v2/components/payment-input/ewallet-modal/ewallet-modal.component';
import { EwalletTransactionModalComponent } from 'app/pos-v2/components/payment-input/ewallet-transaction-modal/ewallet-transaction-modal.component';
import { SaveReceiptV2Component } from 'app/pos-v2/components/save-receipt-v2/save-receipt-v2.component';
import { SaveAcceptanceV2Component } from 'app/pos-v2/components/save-acceptance-v2/save-acceptance-v2.component';
import { CalculationDisplayComponent } from 'app/pos-v2/components/calculation-display/calculation-display.component';
import { CouponContainerComponent } from 'app/pos-v2/components/coupon-container/coupon-container.component';
import { CouponModalComponent } from 'app/components/coupon-modal/coupon-modal.component';
import { EmptyCartComponent } from 'app/pos-v2/components/empty-cart/empty-cart.component';
import { ItemCartV2Component } from 'app/pos-v2/components/item-cart-v2/item-cart-v2.component';
import { ItemCartComponent } from 'app/pos-v2/components/item-cart/item-cart.component';
import { ListItemModalComponent } from 'app/pos-v2/components/list-item-modal/list-item-modal.component';
import { PatientModalComponent } from 'app/pos-v2/components/patient-modal/patient-modal.component';
import { DashboardAnnouncementComponent } from '../dashboard-announcement/dashboard-announcement.component';
import { DashboardBannerComponent } from '../dashboard-banner/dashboard-banner.component';
import { DashboardDataKepegawaianComponent } from '../dashboard-data-kepegawaian/dashboard-data-kepegawaian.component';
import { DashboardKeuanganKasirHarianComponent } from '../dashboard-keuangan-kasir-harian/dashboard-keuangan-kasir-harian.component';
import { DashboardKeuanganKasirMingguanComponent } from '../dashboard-keuangan-kasir-mingguan/dashboard-keuangan-kasir-mingguan.component';
import { DashboardKunjunganPasienComponent } from '../dashboard-kunjungan-pasien/dashboard-kunjungan-pasien.component';
import { DashboardKunjunganPasienHariIniComponent } from '../dashboard-kunjungan-pasien-hari-ini/dashboard-kunjungan-pasien-hari-ini.component';
import { DashboardKunjunganPasienPerDokterComponent } from '../dashboard-kunjungan-pasien-per-dokter/dashboard-kunjungan-pasien-per-dokter.component';
import { DashboardKunjunganPasienPerKlinikComponent } from '../dashboard-kunjungan-pasien-per-klinik/dashboard-kunjungan-pasien-per-klinik.component';
import { DashboardPatientQueueComponent } from '../dashboard-patient-queue/dashboard-patient-queue.component';
import { DashboardQuotesComponent } from '../dashboard-quotes/dashboard-quotes.component';
import { DashboardReminderPackageComponent } from '../dashboard-reminder-package/dashboard-reminder-package.component';
import { DashboardWeatherComponent } from '../dashboard-weather/dashboard-weather.component';
import { InputDatepickerComponent } from '../input-datepicker/input-datepicker.component';
import { OtpInput } from '../otp-input/otp-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { CpptUpdateModalComponent } from 'app/trisakti/form-medis/cppt-form/cppt-update-modal/cppt-update-modal.component';
import { RiwayatPerubahanCpptModalComponent } from 'app/trisakti/form-medis/cppt-form/riwayat-perubahan-cppt-modal/riwayat-perubahan-cppt-modal.component';
import { BeriTanggapanModalComponent } from 'app/trisakti/form-medis/cppt-form/beri-tanggapan-modal/beri-tanggapan-modal.component';
import { PrintRekamMedisMobileComponent } from '../print-rekam-medis-mobile/print-rekam-medis-mobile.component';
import { PrintOdontogramMobileComponent } from '../print-odontogram-mobile/print-odontogram-mobile.component';
import { JadwalDokterFilterModalComponent } from '../jadwal-dokter-filter-modal/jadwal-dokter-filter-modal.component';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { DoughnutChartComponent } from '../doughnut-chart/doughnut-chart.component';
import { DownloadModalComponent } from '../download-modal/download-modal.component';
import { DetailPackageComponent } from '../detail-package/detail-package.component';
import { DetailPackageModalComponent } from '../detail-package-modal/detail-package-modal.component';
import { StatusTransaksiComponent } from '../status-transaksi/status-transaksi.component';
import { ViewFilePasienComponent } from '../view-file-pasien/view-file-pasien.component';
import { HelpdeskFilterModalComponent } from '../helpdesk-filter-modal/helpdesk-filter-modal.component';
import { PegawaiFilterModalComponent } from '../pegawai-filter-modal/pegawai-filter-modal.component';
import { MenuExplorerComponent } from '../menu-explorer/menu-explorer.component';
import { ContactRoomItemComponent } from 'app/customer-service/components/contact-room-item/contact-room-item.component';
import { CustomerServiceMessageComponent } from 'app/customer-service/components/customer-service-message/customer-service-message.component';
import { MedicalCompletenessComponent } from '../medical-completeness/medical-completeness.component';

@NgModule({
  imports: [
    WebcamModule,
    CommonModule,
    CommonUiModule,
    CKEditorModule,
    DirectivesModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    NgOtpInputModule,
    IonicSelectableModule,
    NgCalendarModule,
    FullCalendarModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA51GSH4pA3nq1pYe106nod-WYq70iXEUQ',
      libraries: ['places'],
    }),
  ],
  providers: [Camera, File, ForegroundNotificationService, NgxImageCompressService, GetPhotoService, PreviewAnyFile],
  declarations: [
    AddActionsModalComponent,
    AddItemsModalComponent,
    AppointmentActionModalComponent,
    AppointmentCardComponent,
    AppointmentChatMessageComponent,
    AssesmentModalComponent,
    BarChartComponent,
    BniEcollectionInputComponent,
    BeriTanggapanModalComponent,
    CalculationDisplayComponent,
    CardInputPaymentComponent,
    CashInputComponent,
    CashInputPaymentComponent,
    CashInputModalComponent,
    CashierQueueFilterModalComponent,
    CashierQueueItemComponent,
    ChangeDateModalComponent,
    ConnectionErrorComponent,
    CouponListItemComponent,
    CouponListItemDetailComponent,
    CouponListModalComponent,
    CouponModalComponent,
    CouponContainerComponent,
    ContactRoomItemComponent,
    CustomerServiceMessageComponent,
    CpptUpdateModalComponent,
    DashboardAppointmentsComponent,
    DashboardAppointmentsOfflineComponent,
    DashboardAnnouncementComponent,
    DashboardBannerComponent,
    DashboardDataKepegawaianComponent,
    DashboardKeuanganKasirHarianComponent,
    DashboardKeuanganKasirMingguanComponent,
    DashboardKunjunganPasienComponent,
    DashboardKunjunganPasienHariIniComponent,
    DashboardKunjunganPasienPerDokterComponent,
    DashboardKunjunganPasienPerKlinikComponent,
    DashboardOperationScheduleComponent,
    DashboardPatientQueueComponent,
    DashboardQuotesComponent,
    DashboardReminderPackageComponent,
    DashboardWeatherComponent,
    DateFilterModalComponent,
    DebitCreditInputComponent,
    DetailResepDigitalCardComponent,
    DetailPaymentModalComponent,
    DetailPackageComponent,
    DetailPackageModalComponent,
    DoctorChatCreateModalComponent,
    DoughnutChartComponent,
    DownloadModalComponent,
    EditCaraPemakaianModalComponent,
    EditResepItemModalComponent,
    EdcInputComponent,
    EmployeeInputComponent,
    EmptyCartComponent,
    EwalletInputPaymentComponent,
    EwalletModalComponent,
    EwalletTransactionModalComponent,
    FilterAntrianRawatInapComponent,
    FilterRangeDateModalComponent,
    FilterResepDigitalRanapModalComponent,
    FilterSuratModalComponent,
    FormAlergiComponent,
    FormAssesmentMedisComponent,
    FormBahanMedisComponent,
    FormCpptComponent,
    FormDataPemeriksaanComponent,
    FormDataPemeriksaanLaboratoriumComponent,
    FormIcdNineComponent,
    FormIcdTenComponent,
    FormOdontogramComponent,
    FormPemeriksaanLaboratoriumComponent,
    FormPemeriksaanVitalComponent,
    FormRingkasanPulangComponent,
    FormRujukRadiologiComponent,
    FormRujukRawatComponent,
    FormTindakanDiagnosaComponent,
    FormTindakanMedisComponent,
    FormTindakanLaboratoriumComponent,
    FormTindakanRadiologiComponent,
    FotoPasienComponent,
    FullPageLoadingComponent,
    HeaderComponent,
    HistoryPemeriksaanLaboratoriumComponent,
    InlineLoadingComponent,
    InputFileComponent,
    InsuranceInputComponent,
    InsuranceInputPaymentComponent,
    ItemCartComponent,
    ItemCartV2Component,
    JadwalDokterFilterModalComponent,
    LineChartComponent,
    ListItemModalComponent,
    ManageCetakKasirFilterModalComponent,
    ManageItemFilterModalComponent,
    ManageTarifTindakanFilterModalComponent,
    ManageServiceFilterModalComponent,
    MedicalCompletenessComponent,
    NoDataComponent,
    NoDataV2Component,
    OdontogramTableComponent,
    PaidPaymentItemComponent,
    PatientFilterModalComponent,
    PatientModalComponent,
    PaymentAcceptanceComponent,
    PaymentInputHeaderComponent,
    PaymentReceiptComponent,
    PaymentStatusItemComponent,
    PaymentStatusesModalComponent,
    PegawaiInputPaymentComponent,
    PengeluaranGudangCardComponent,
    PhotoCardComponent,
    PindahDokterModalComponent,
    PosFooterComponent,
    PosItemComponent,
    PosItemBoxComponent,
    PosNewPatientModalComponent,
    PosPhoneModalComponent,
    PpnEditModalComponent,
    PreviewPhotoModalComponent,
    PrintComponent,
    PrintResepComponent,
    PrintRekamMedisMobileComponent,
    PrintOdontogramMobileComponent,
    ProcessingEdcModalComponent,
    RiwayatPerubahanCpptModalComponent,
    SaveAcceptanceV2Component,
    SaveResepComponent,
    SaveReceiptV2Component,
    SaleListComponent,
    SaleListItemComponent,
    SearchableInputComponent,
    SearchableSelectModalComponent,
    SearchableSelectComponent,
    SearchKaryawanComponent,
    SendPhotoModalComponent,
    StackedBarChartComponent,
    StatusPembayaranModalComponent,
    StatusTransaksiComponent,
    TagihanPasienCardComponent,
    TelaahObatModalComponent,
    ToothComponent,
    TeethComponent,
    ToothInputModalComponent,
    TransferInputPaymentComponent,
    UpdateInvoiceModalComponent,
    UploadPhotoComponent,
    OperationScheduleCardComponent,
    OtpInput,
    VideoCallPopoverComponent,
    VirtualAccountNumberModalComponent,
    VisitHistoryModalComponent,
    ManageDoctorScheduleFilterModalComponent,
    MenuExplorerComponent,
    AddHolidayScheduleModalComponent,
    AddKeywordAnamnesisComponent,
    AddKeywordPgComponent,
    AddKeywordAdimeComponent,
    DeleteKeywordPgComponent,
    DeleteKeywordPgV2Component,
    AbsentHistoryModalComponent,
    KeywordModalComponent,
    KalenderJadwalPasienFilterModalComponent,
    ViewFilePasienComponent,
    VerifikasiResepModalComponent,
    PrintOdontogramComponent,
    PrintSuratSakitComponent,
    PrintSuratSehatComponent,
    FormDietKhususComponent,
    PrintRekamMedisComponent,
    InputDatepickerComponent,
    HelpdeskFilterModalComponent,
    PegawaiFilterModalComponent,
  ],
  exports: [
    AddActionsModalComponent,
    AddItemsModalComponent,
    AppointmentActionModalComponent,
    AppointmentCardComponent,
    AppointmentChatMessageComponent,
    AssesmentModalComponent,
    AgmCoreModule,
    BarChartComponent,
    BniEcollectionInputComponent,
    BeriTanggapanModalComponent,
    CalculationDisplayComponent,
    CardInputPaymentComponent,
    CashInputComponent,
    CashInputPaymentComponent,
    CashInputModalComponent,
    CashierQueueFilterModalComponent,
    CashierQueueItemComponent,
    ChangeDateModalComponent,
    CKEditorModule,
    ConnectionErrorComponent,
    CommonUiModule,
    CouponListItemComponent,
    CouponListItemDetailComponent,
    CouponListModalComponent,
    CouponModalComponent,
    CouponContainerComponent,
    ContactRoomItemComponent,
    CustomerServiceMessageComponent,
    CpptUpdateModalComponent,
    DashboardAppointmentsComponent,
    DashboardAppointmentsOfflineComponent,
    DashboardAnnouncementComponent,
    DashboardBannerComponent,
    DashboardDataKepegawaianComponent,
    DashboardKeuanganKasirHarianComponent,
    DashboardKeuanganKasirMingguanComponent,
    DashboardKunjunganPasienComponent,
    DashboardKunjunganPasienHariIniComponent,
    DashboardKunjunganPasienPerDokterComponent,
    DashboardKunjunganPasienPerKlinikComponent,
    DashboardOperationScheduleComponent,
    DashboardPatientQueueComponent,
    DashboardQuotesComponent,
    DashboardReminderPackageComponent,
    DashboardWeatherComponent,
    DetailResepDigitalCardComponent,
    DetailPaymentModalComponent,
    DateFilterModalComponent,
    DebitCreditInputComponent,
    DetailPackageComponent,
    DetailPackageModalComponent,
    DoctorChatCreateModalComponent,
    DoughnutChartComponent,
    DownloadModalComponent,
    EditCaraPemakaianModalComponent,
    EditResepItemModalComponent,
    EdcInputComponent,
    EmployeeInputComponent,
    EmptyCartComponent,
    EwalletInputPaymentComponent,
    EwalletModalComponent,
    EwalletTransactionModalComponent,
    FilterAntrianRawatInapComponent,
    FilterRangeDateModalComponent,
    FilterResepDigitalRanapModalComponent,
    FilterSuratModalComponent,
    FormAlergiComponent,
    FormAssesmentMedisComponent,
    FormBahanMedisComponent,
    FormCpptComponent,
    FormDataPemeriksaanComponent,
    FormDataPemeriksaanLaboratoriumComponent,
    FormIcdNineComponent,
    FormIcdTenComponent,
    FormOdontogramComponent,
    FormPemeriksaanLaboratoriumComponent,
    FormPemeriksaanVitalComponent,
    FormRingkasanPulangComponent,
    FormRujukRadiologiComponent,
    FormRujukRawatComponent,
    FormTindakanDiagnosaComponent,
    FormTindakanMedisComponent,
    FormTindakanLaboratoriumComponent,
    FormTindakanRadiologiComponent,
    FotoPasienComponent,
    FullPageLoadingComponent,
    HeaderComponent,
    HistoryPemeriksaanLaboratoriumComponent,
    InlineLoadingComponent,
    InputFileComponent,
    InsuranceInputComponent,
    InsuranceInputPaymentComponent,
    ItemCartComponent,
    ItemCartV2Component,
    JadwalDokterFilterModalComponent,
    LineChartComponent,
    ListItemModalComponent,
    ManageCetakKasirFilterModalComponent,
    ManageItemFilterModalComponent,
    ManageTarifTindakanFilterModalComponent,
    ManageServiceFilterModalComponent,
    MedicalCompletenessComponent,
    NoDataComponent,
    NoDataV2Component,
    OdontogramTableComponent,
    PaidPaymentItemComponent,
    PatientFilterModalComponent,
    PatientModalComponent,
    PaymentAcceptanceComponent,
    PaymentInputHeaderComponent,
    PaymentReceiptComponent,
    PaymentStatusItemComponent,
    PaymentStatusesModalComponent,
    PegawaiInputPaymentComponent,
    PengeluaranGudangCardComponent,
    PhotoCardComponent,
    PindahDokterModalComponent,
    PosFooterComponent,
    PosItemComponent,
    PosItemBoxComponent,
    PosNewPatientModalComponent,
    PosPhoneModalComponent,
    PpnEditModalComponent,
    PreviewPhotoModalComponent,
    PrintComponent,
    PrintResepComponent,
    PrintRekamMedisMobileComponent,
    PrintOdontogramMobileComponent,
    ProcessingEdcModalComponent,
    RiwayatPerubahanCpptModalComponent,
    SaveAcceptanceV2Component,
    SaveResepComponent,
    SaveReceiptV2Component,
    SaleListComponent,
    SaleListItemComponent,
    SearchableInputComponent,
    SearchableSelectModalComponent,
    SearchableSelectComponent,
    SearchKaryawanComponent,
    SendPhotoModalComponent,
    StackedBarChartComponent,
    StatusPembayaranModalComponent,
    StatusTransaksiComponent,
    TagihanPasienCardComponent,
    TelaahObatModalComponent,
    ToothComponent,
    TeethComponent,
    ToothInputModalComponent,
    TransferInputPaymentComponent,
    UpdateInvoiceModalComponent,
    UploadPhotoComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MaterialModule,
    OperationScheduleCardComponent,
    OtpInput,
    ReactiveFormsModule,
    FormsModule,
    IonicSelectableModule,
    IonicSelectableModule,
    VideoCallPopoverComponent,
    VirtualAccountNumberModalComponent,
    VisitHistoryModalComponent,
    ManageDoctorScheduleFilterModalComponent,
    MenuExplorerComponent,
    WebcamModule,
    NgCalendarModule,
    FullCalendarModule,
    AddHolidayScheduleModalComponent,
    AddKeywordAnamnesisComponent,
    AddKeywordPgComponent,
    AddKeywordAdimeComponent,
    DeleteKeywordPgComponent,
    DeleteKeywordPgV2Component,
    AbsentHistoryModalComponent,
    KeywordModalComponent,
    KalenderJadwalPasienFilterModalComponent,
    ViewFilePasienComponent,
    VerifikasiResepModalComponent,
    PrintOdontogramComponent,
    PrintSuratSakitComponent,
    PrintSuratSehatComponent,
    FormDietKhususComponent,
    PrintRekamMedisComponent,
    InputDatepickerComponent,
    HelpdeskFilterModalComponent,
    PegawaiFilterModalComponent,
  ],
})
export class GlobalModule {}
