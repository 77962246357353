import { animate, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from 'app/services/toast.service';
import { VideoCallService } from 'app/services/video-call.service';
import { fromEvent, interval, Observable, Subject } from 'rxjs';
import { map, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as tv from 'twilio-video';

interface Position {
  x: number;
  y: number;
}

@Component({
  selector: 'app-video-call-popover',
  templateUrl: './video-call-popover.component.html',
  styleUrls: ['./video-call-popover.component.scss'],
})
export class VideoCallPopoverComponent implements OnInit, OnDestroy {
  @ViewChild('remoteMedia', { read: ElementRef, static: true })
  remoteMedia: ElementRef;
  @ViewChild('popOverRoot', { read: ElementRef, static: true })
  popOverRoot: ElementRef;
  private unsubscribe$ = new Subject<void>();

  private touchEvent$: Observable<Position>;
  public micMute = false;
  public position: Position = {
    x: 16,
    y: 16,
  };
  public isReorientating = false;

  constructor(
    private alertController: AlertController,
    private router: Router,
    private toastService: ToastService,
    private videoCallService: VideoCallService
  ) {}

  private _getClosestEdge(pos: Position) {
    const popOverRect = this.popOverRoot.nativeElement.getBoundingClientRect();
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;
    const edges: Position[] = [
      { x: 0, y: 0 },
      { x: 0, y: pageHeight },
      { x: pageWidth, y: 0 },
      { x: pageWidth, y: pageHeight },
    ];
    const distances = edges.map((edge) => {
      const subY = pos.y - edge.y;
      const subX = pos.x - edge.x;
      return Math.sqrt(subY * subY + subX * subX);
    });
    const minVal = Math.min(...distances);
    const index = distances.indexOf(minVal);
    const targetEdge = edges[index];
    if (targetEdge.x !== 0) targetEdge.x = targetEdge.x - popOverRect.width;
    if (targetEdge.y !== 0) targetEdge.y = targetEdge.y - popOverRect.height;
    return targetEdge;
  }

  // Register popover events, such as moving/dragging.
  private _registerBubbleEvents() {
    const popOverRect = this.popOverRoot.nativeElement.getBoundingClientRect();
    const popOverElement = this.popOverRoot.nativeElement;
    const pageWidth = window.innerWidth - popOverRect.width;
    const pageHeight = window.innerHeight - popOverRect.height;
    this.position = { x: pageWidth - 16, y: pageHeight - 16 }; // Init default position

    this.touchEvent$ = fromEvent<TouchEvent>(popOverElement, 'touchstart').pipe(
      switchMap((start) => {
        return fromEvent<TouchEvent>(popOverElement, 'touchmove').pipe(
          map((move) => {
            move.preventDefault();
            const touchX = move.touches[0].clientX - popOverRect.width / 2;
            const touchY = move.touches[0].clientY - popOverRect.height / 2;
            let calcX = touchX;
            let calcY = touchY;

            if (touchX < 0) calcX = 0;
            if (touchX > pageWidth) calcX = pageWidth;
            if (touchY < 0) calcY = 0;
            if (touchY > pageHeight) calcY = pageHeight;

            return {
              x: calcX,
              y: calcY,
            };
          }),
          takeUntil(fromEvent(popOverElement, 'touchend')),
          takeUntil(fromEvent(popOverElement, 'touchcancel'))
        );
      })
    );

    this.touchEvent$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.position = res;
    });

    fromEvent<TouchEvent>(popOverElement, 'touchend')
      .pipe(
        tap(() => (this.isReorientating = true)),
        map((res) => {
          return {
            x: Math.round(res.changedTouches[0].pageX),
            y: Math.round(res.changedTouches[0].pageY),
          };
        })
      )
      .subscribe((res: Position) => {
        this.isReorientating = true;
        // set back to false on transition completed. refactor later.
        setTimeout(() => (this.isReorientating = false), 200);
        const closestEdge = this._getClosestEdge(res);

        const offsetX = closestEdge.x === 0 ? 16 : -16;
        const offsetY = closestEdge.y === 0 ? 16 : -16;

        this.position = {
          x: closestEdge.x + offsetX,
          y: closestEdge.y + offsetY,
        };
      });
  }

  private _attachVideoTrack(track: tv.RemoteVideoTrack) {
    const mediaElement = track.attach();
    mediaElement.setAttribute('height', '200');
    this.remoteMedia.nativeElement.appendChild(mediaElement);
  }

  private _participantConnected(participant: any) {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        if (publication.kind === 'video') {
          const track = publication.track as tv.RemoteVideoTrack;
          this._attachVideoTrack(track);
        } else if (publication.kind === 'audio') {
          const track = publication.track as tv.RemoteAudioTrack;
          this.remoteMedia.nativeElement.appendChild(track.attach());
        }
      }
    });

    participant.on('trackSubscribed', (track) => {
      console.log('track', track);
      this.remoteMedia.nativeElement.appendChild(track.attach());
    });
  }

  getFeeds() {
    if (this.videoCallService.remoteParticipant) {
      const remoteParticipant = this.videoCallService.remoteParticipant;
      this._participantConnected(remoteParticipant);
    } else {
      // this.videoCallService.dismissPopOver();
      this.videoCallService.participantConnected$.pipe(takeUntil(this.unsubscribe$)).subscribe((participant) => {
        if (participant !== undefined) {
          this._participantConnected(participant);
        }
      });
    }
  }

  async resumeCall() {
    if (this.videoCallService.resumeURL) {
      await this.router.navigateByUrl(this.videoCallService.resumeURL);
      this.videoCallService.dismissPopOver();
    }
  }

  async disconnectCall() {
    await this.videoCallService.disconnectRoom();
    this.videoCallService.dismissPopOver();
  }

  async showLeaveAlert() {
    const leaveAlert = await this.alertController.create({
      backdropDismiss: false,
      message: 'End video call?',
      buttons: [
        {
          text: 'Yes',
          role: 'yes',
          handler: () => {
            this.disconnectCall();
          },
        },
        {
          text: 'No',
          role: 'no',
          handler: () => {},
        },
      ],
    });
    leaveAlert.present();
  }

  async switchCamera() {
    await this.videoCallService.toggleCamera();
  }

  async toggleMute() {
    this.videoCallService.toggleMicrophone();
  }

  ngOnInit() {
    this._registerBubbleEvents();
    this.getFeeds();

    this.videoCallService.roomDisconnected$.pipe(takeUntil(this.unsubscribe$)).subscribe((room) => {
      this.toastService.bottomToast(`Call ended.`, 2000);
      this.videoCallService.dismissPopOver();
    });

    this.videoCallService.micMute$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.micMute = res;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
