import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';

import { Storage } from '@ionic/storage';
import { storageDict } from './storage-dict';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) {}

  getItem(key: string): Observable<any> {
    return from(this.storage.get(key));
  }

  setItem(key: string, value: any): Observable<any> {
    return from(this.storage.set(key, value));
  }

  removeItem(key: string): Observable<any> {
    return from(this.storage.remove(key));
  }

  async clear(): Promise<void> {
    const whitelist = [storageDict.klinik, storageDict.customerCodeHistory, storageDict.fcmToken];
    const keys = await this.storage.keys();
    const removed = keys.filter((key) => !whitelist.includes(key));
    removed.forEach((key) => this.storage.remove(key));
    return;
  }
}
