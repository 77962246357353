import { SidebarMenu } from 'app/models/application-menu.model';

export const sidebarStaticMenuCustomAdmin: SidebarMenu[] = [
  {
    title: 'CMS',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Customer Service',
        icon: 'time-outline',
        url: '/customer-service',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
      {
        title: 'Dashboard Whatsapp',
        icon: 'time-outline',
        url: '/dashboard-whatsapp',
        clinicType: ['klinik', 'rs', 'rsp'],
      },
    ],
  },
  {
    title: 'Master',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Master Instalasi',
        icon: '',
        url: '/master-installasi',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Poli Klinik',
        icon: '',
        url: '/master-poli-klinik',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Gudang',
        icon: '',
        url: '/master-gudang',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Rak Gudang',
        icon: '',
        url: '/master-rak',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Supplier',
        icon: '',
        url: '/master-supplier',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Cuti',
        icon: '',
        url: '/master-cuti',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Cuti Disable',
        icon: '',
        url: '/master-cuti-disable',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Unit Kerja',
        icon: '',
        url: '/master-unit-kerja',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Jabatan',
        icon: '',
        url: '/master-jabatan',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Metode Pembayaran',
        icon: '',
        url: '/master-metode-pembayaran',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Event',
        icon: ``,
        url: '/master-event',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Banner',
        url: '/master-banner',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Miscafac',
        url: '/master-miscafac',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Signa',
        url: '/master-signa',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Biaya Tambahan Kasir',
        url: '/master-biaya-tambahan-kasir',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Master Tarif Odontogram',
        url: '/master-tarif-odontogram',
        icon: ``,
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
  {
    title: 'Pengaturan Software',
    icon: 'assets/icon/navigation/setting.svg',
    children: [
      {
        title: 'Manage User & Login',
        icon: 'key',
        url: '/manage-login',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
      {
        title: 'Manage Cuti Klinik',
        icon: 'key',
        url: '/setting-cuti',
        clinicType: ['klinik', 'rs', 'rsp', 'partner'],
      },
    ],
  },
];
