import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HyphenService {
  constructor() {}

  addHyphen(str: string) {
    return this.chunk(str.replace(/\-/g, ''), 2, true).join('-');
  }

  addHyphenForInput(str: string) {
    return this.chunk(str.replace(/\-/g, ''), 2).join('-');
  }

  removeLeadingZeroes(str: string) {
    return str.replace(/^0+/, '');
  }

  private addLeadingZero(str: string) {
    return '0' + str;
  }

  private addLeadingZeroes(str: string) {
    const len = str.length;

    if (len < 6) {
      while (str.length < 6) {
        str = this.addLeadingZero(str);
      }
    } else if (len % 2 !== 0) {
      str = this.addLeadingZero(str);
    }

    return str;
  }

  private chunk(str: string, pos: number, display = false) {
    if (display) {
      str = this.addLeadingZeroes(str);
    }

    let ret = [];
    let i: number;
    let len: number;

    for (i = 0, len = str.length; i < len; i += pos) {
      ret = [...ret, str.substr(i, pos)];
    }

    return ret;
  }
}
