import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataKlinik, DataKlinikV2 } from '../../models/data-klinik.model';
import { DataKlinikApiService } from '../api/data-klinik.api.service';

@Injectable({
  providedIn: 'root',
})
export class DataKlinikService {
  constructor(private dataKlinikApiService: DataKlinikApiService) {}

  public getAll(idDataKlinik?: number): Observable<DataKlinik> {
    return this.dataKlinikApiService.getDataKlinik(idDataKlinik);
  }

  public getAllV2(idDataKlinik?: any): Observable<DataKlinikV2> {
    return this.dataKlinikApiService.getDataKlinikV2(idDataKlinik);
  }

  // Full Data
  public getAllDataKlinikV2(idDataKlinik?: number): Observable<DataKlinikV2> {
    return this.dataKlinikApiService.getAllDataKlinikV2(idDataKlinik);
  }

  public getDataHeadOffice(idGroupDataKlinik: number): Observable<DataKlinikV2> {
    return this.dataKlinikApiService.getDataHeadOffice(idGroupDataKlinik);
  }

  public searchByCustomerCode(code: string): Observable<DataKlinikV2> {
    return this.dataKlinikApiService.searchByCustomerCode(code);
  }

  public searchByURL(url: string): Observable<DataKlinikV2> {
    return this.dataKlinikApiService.searchByURL(url);
  }
}
