import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max',
})
export class MathPipe implements PipeTransform {
  transform(number: number): any {
    return Math.max(number, 0);
  }
}
