import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataKlinik } from 'app/models/data-klinik.model';
import { environment } from 'environments/environment';
import { from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { storageDict } from './storage/storage-dict';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebHookService {
  // private httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   }),
  // };

  private clientIp = '';
  private clinic: DataKlinik;
  private readonly url = `https://discord.com/api/webhooks/1046685245912653915/IdtTovRIzIRmaY3tQo2RgLdPzfQezh7ZTf_IBbLYBN5PTmO1zWiQU_blIu-r8FHT8Q65`;

  constructor(private httpClient: HttpClient, private storage: StorageService) {
    this.storage.getItem(storageDict.klinik).subscribe((res) => {
      this.clinic = res;
    });
  }

  public postHttpError(status: string, statusText: string, message: string, body: any, footer: string) {
    let logoName = String(this.clinic?.logo).split('/').pop();
    let bracket = '```';
    let value = body === null ? 'null' : body;
    const data = {
      username: 'Mobile Klinik',
      embeds: [
        {
          author: {
            name: `${this.clinic?.nama}`,
          },
          title: `${status} - ${statusText}`,
          color: 16711680,
          description: `${message}`,
          fields: [
            {
              name: 'HttpRequestBody:',
              value: `${bracket}${value}${bracket}`,
            },
          ],
          image: {
            url: `${environment.urlIconBucket}/${logoName}`,
          },
          footer: {
            text: `${footer}`,
          },
        },
      ],
    };
    return this.post(data);
    // return this.httpClient
    //   .post(
    //     `https://discord.com/api/webhooks/1045593836321255465/G6vG7DOHgWomhkEEq3opM9IjraCi2bf7jizrpXbEwRhuh6BWCInVsfe2mao2QS0H6EXq`,
    //     data,
    //     this.httpOptions
    //   )
    //   .pipe(catchError((err) => throwError(err)));
  }

  public postClientError(name: string, message: string, footer: string) {
    let logoName = String(this.clinic?.logo).split('/').pop();
    const data = {
      username: 'Mobile Klinik',
      embeds: [
        {
          author: {
            name: `${this.clinic?.nama}`,
          },
          title: `${name}`,
          color: 16711680,
          description: `${message}`,
          image: {
            url: `${environment.urlIconBucket}/${logoName}`,
          },
          footer: {
            text: `${footer}`,
          },
        },
      ],
    };
    return this.post(data);

    // return this.httpClient
    //   .post(
    //     `https://discord.com/api/webhooks/1045593836321255465/G6vG7DOHgWomhkEEq3opM9IjraCi2bf7jizrpXbEwRhuh6BWCInVsfe2mao2QS0H6EXq`,
    //     data,
    //     this.httpOptions
    //   )
    //   .pipe(catchError((err) => throwError(err)));
  }

  private post(body: any) {
    return from(
      fetch(this.url, {
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
      })
    );
  }

  public setClientIP(ipAddress: string) {
    this.clientIp = ipAddress;
  }
}
