export const environment = {
  type: 'production',
  url: 'https://core.periksa.tech/api/v1',
  urlV2: 'https://core.periksa.tech/api/v2',
  urlPayment: 'https://dompet.periksa.net/v1',
  urlPaymentV2: 'https://dompet.periksa.net/v2',
  urlCoupon: 'https://coupon.periksa.tech/v1',
  urlChat: 'https://chat.periksa.tech/v1',
  urlChatV2: 'https://chat.periksa.tech/v2',
  urlSearchEngine: 'https://cari.periksa.tech/v1.0.0',
  urlBFF: 'https://backend-web-klinik.periksa.tech',
  urlMessaging: 'https://messenger.periksa.tech/v1.0.0',
  urlAuth: 'https://periksaapis.com/auth',
  urlGo: 'https://periksaapis.com',
  urlIconBucket: 'https://icons-rwji6t52cv3rp6f8.s3.ap-southeast-1.amazonaws.com/logos',
  urlAi: 'https://ai.periksa.net',
  urlInfoV1: 'https://core.periksa.info/api/v1',
  urlInfoV2: 'https://core.periksa.info/api/v2',
  production: true,
  klinik: 1,
  groupKlinik: 1,
  exclusiveLogin: false,
  theme: 'light', // 'light' or 'dark'
  pusher: {
    key: 'b68c777ddf6aa79a148b',
    cluster: 'ap1'
  },
  firebase: {
    apiKey: "AIzaSyA51GSH4pA3nq1pYe106nod-WYq70iXEUQ",
    authDomain: "klinikmobile-7adce.firebaseapp.com",
    databaseURL: "https://klinikmobile-7adce.firebaseio.com",
    projectId: "klinikmobile-7adce",
    storageBucket: "klinikmobile-7adce.appspot.com",
    messagingSenderId: "663772314626",
    appId: "1:663772314626:web:a8d856d5b0186f7d277b1b",
    measurementId: "G-QYHY1EGP09",
    vapidKey: 'BHFFdbbSaNZNyIMks46wGAAfABwuWNu-1a0fZ5soKqhJDAoPYeEBktwKi7UbkfMNM8nKK6HOklO4LvgCBXks6EU',
  },
  apiId: 'klinik.periksa.id',
  apiPass: 'mgjZX5atWmNtAZS6',
  apiSecret: 'Qfk#!P@F3zFAB9ov',
  appVersion: require('../../package.json').version,
};
