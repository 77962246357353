import { HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TeamsWebhookService {
  private readonly WEBHOOK_URL = `https://periksasolusiindonesia.webhook.office.com/webhookb2/6f694d11-d87f-4681-b48d-dbac6fc7fc44@fc424dbf-dc78-4bc4-8546-0d5e8f0f1c67/IncomingWebhook/81fd465eccc64a37b16069e7e84e51db/c264e1f9-6c91-4706-8cf6-36ddfab81a17`;

  private clientIp = '';

  constructor() {}

  public setClientIP(ipAddress: string) {
    this.clientIp = ipAddress;
  }

  private buildHttpErrorCard(err: HttpErrorResponse, request: HttpRequest<any>) {
    return {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'TextBlock',
          size: 'Large',
          weight: 'Bolder',
          text: 'Super App - HTTP Request Error',
        },
        {
          type: 'TextBlock',
          text: `${request.method} ${request.urlWithParams}`,
          wrap: true,
          size: 'Medium',
          weight: 'Bolder',
        },
        {
          type: 'TextBlock',
          text: `${err.status} ${err.statusText}`,
          wrap: true,
          color: 'Attention',
        },
        {
          type: 'Container',
          style: 'default',
          items: [
            {
              type: 'TextBlock',
              text: 'Request Body',
              wrap: true,
              weight: 'Bolder',
              separator: true,
            },
            {
              type: 'RichTextBlock',
              inlines: [
                {
                  fontType: 'Monospace',
                  type: 'TextRun',
                  text: !request.body ? `(Request doesn't have body)` : `${JSON.stringify(request.body)}`,
                },
              ],
            },
            {
              type: 'TextBlock',
              text: 'Response Body',
              wrap: true,
              separator: true,
              weight: 'Bolder',
            },
            {
              type: 'RichTextBlock',
              inlines: [
                {
                  fontType: 'Monospace',
                  type: 'TextRun',
                  text: `${JSON.stringify(err.error)}`,
                },
              ],
            },
            {
              type: 'TextBlock',
              text: 'User Agent',
              wrap: true,
              separator: true,
              weight: 'Bolder',
            },
            {
              type: 'RichTextBlock',
              inlines: [
                {
                  fontType: 'Monospace',
                  type: 'TextRun',
                  text: `${window.navigator.userAgent}`,
                },
              ],
            },
          ],
          separator: true,
        },
        {
          type: 'FactSet',
          facts: [
            {
              title: 'Sender IP',
              value: `${this.clientIp || '(Not Available)'}`,
            },
            {
              title: 'Referer URL',
              value: `${window.location.href}`,
            },
            {
              title: 'Request Time',
              value: moment().format('DD MMM YYYY HH:mm:ss'),
            },
          ],
          separator: true,
        },
      ],
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.2',
    };
  }

  private buildClientErrorContent(errStack: string) {
    return {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'TextBlock',
          size: 'Large',
          weight: 'Bolder',
          text: 'Super App - Client Error/Crash',
        },
        {
          type: 'Container',
          separator: true,
          items: [
            {
              type: 'TextBlock',
              text: 'Error Message / Stack',
              wrap: true,
              separator: true,
              size: 'Medium',
              weight: 'Bolder',
            },
            {
              type: 'RichTextBlock',
              inlines: [
                {
                  type: 'TextRun',
                  fontType: 'Monospace',
                  text: errStack,
                },
              ],
            },
            {
              type: 'TextBlock',
              text: 'User Agent',
              wrap: true,
              weight: 'Bolder',
              size: 'Medium',
              separator: true,
            },
            {
              type: 'RichTextBlock',
              inlines: [
                {
                  type: 'TextRun',
                  fontType: 'Monospace',
                  text: `${window.navigator.userAgent}`,
                },
              ],
            },
          ],
        },
        {
          type: 'FactSet',
          facts: [
            {
              title: 'Client IP',
              value: `${this.clientIp || '(Not Available)'}`,
            },
            {
              title: 'Client URL',
              value: `${window.location.href}`,
            },
            {
              title: 'Time',
              value: moment().format('DD MMM YYYY HH:mm:ss'),
            },
          ],
          height: 'stretch',
        },
      ],
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.3',
    };
  }

  private sendToWebhook(body: any) {
    return from(
      fetch(this.WEBHOOK_URL, {
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'no-cors',
      })
    );
  }

  public sendHttpException(err: HttpErrorResponse, request: HttpRequest<any>): Observable<any> {
    if (environment.production === false) return of(null);

    const requestBody = {
      type: 'message',
      attachments: [
        {
          contentType: 'application/vnd.microsoft.card.adaptive',
          contentUrl: null,
          content: this.buildHttpErrorCard(err, request),
        },
      ],
    };

    return this.sendToWebhook(requestBody);
  }

  public sendClientError(err: Error): Observable<any> {
    if (environment.production === false) return of(null);
    if (err instanceof HttpErrorResponse) return of(null); // Already handled on http interceptor.

    let errDescription = '';
    if (err.stack) {
      errDescription = err.stack;
    } else {
      errDescription = `${err.name} ${err.message}`;
    }

    const requestBody = {
      type: 'message',
      attachments: [
        {
          contentType: 'application/vnd.microsoft.card.adaptive',
          contentUrl: null,
          content: this.buildClientErrorContent(errDescription),
        },
      ],
    };

    return this.sendToWebhook(requestBody);
  }
}
