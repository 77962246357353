interface NotificationPayload {
  title?: string;
  body?: string;
}

export class NotificationMessage {
  constructor(
    public type?: 'data' | 'notification',
    public data?: any,
    public notification?: NotificationPayload,
    public redirect?: string,
    public timestamp?: Date
  ) {}
}
