import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

type UpdateStatus =
  | 'update-available'
  | 'update-not-available'
  | 'update-downloaded'
  | 'uninitialized'
  | 'error'
  | 'checking';

@Injectable({
  providedIn: 'root',
})
export class ElectronIPCService {
  private ipcRenderer: any;
  private updateStatus$: BehaviorSubject<UpdateStatus>;
  private isInitialized = false;
  private electronVersion = '';

  constructor(private platform: Platform) {}

  public initialize() {
    if (this.platform.is('electron')) {
      this.updateStatus$ = new BehaviorSubject<UpdateStatus>('uninitialized');
      this.ipcRenderer = window.require('electron').ipcRenderer;

      this.ipcRenderer.on('app-version', (event, arg: string) => {
        this.electronVersion = arg;
      });

      this.ipcRenderer.on('ipc-msg', (event, arg) => {
        console.log(arg);
      });

      this.ipcRenderer.on('update-status', (event, arg: UpdateStatus) => {
        this.updateStatus$.next(arg);
      });

      this.ipcRenderer.send('ipc-connect');
      this.ipcRenderer.send('check-update');

      this.isInitialized = true;
    }
  }

  public fetchUpdateStatus() {
    return this.updateStatus$
      .pipe
      // tap((res) => console.log(res)),
      ();
  }

  public checkElectronUpdate() {
    if (this.platform.is('electron') && this.isInitialized) {
      this.ipcRenderer.send('check-update');
      this.updateStatus$.next('checking');
    }
  }

  public restartApplication() {
    if (this.platform.is('electron') && this.isInitialized) {
      this.ipcRenderer.send('restart-app');
    }
  }

  public getVersion() {
    if (this.platform.is('electron') && this.isInitialized) {
      return this.electronVersion;
    }
  }
}
