import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { take, timeout } from 'rxjs/operators';
import { DataKlinik } from '../models/data-klinik.model';
import { storageDict } from './storage/storage-dict';

@Injectable({
  providedIn: 'root',
})
export class ClinicEnvironmentService {
  /**
   * Works as a cache/buffer stored in service, to get directly from the storage will be tiring,
   * since the operation is a promise/asynchronous.
   */
  private _clinicId: number;
  private _clinicGroupId: number;
  private _timeZone: string;
  private _listenerInitialized = false;
  public authState$ = new Subject<boolean>();
  public clinicSet$ = new Subject<any>();

  constructor(private storage: Storage) {
    this.storage.get(storageDict.klinik).then((res) => {
      if (res) {
        this._clinicId = res.id;
        this._clinicGroupId = res.idGroupDataKlinik;
        this._timeZone = res.timeZone;
        this.clinicSet$.next(res);
      }
    });
    this.initService();
  }

  private initService() {
    if (!this._listenerInitialized) {
      this._listenerInitialized = true;
      this.authState$.subscribe((authed) => {
        if (!this._clinicId) {
          this.storage.get(storageDict.klinik).then((val) => {
            if (val) {
              // console.log('klinik ', val.id);
              this._clinicId = val.id;
              this._clinicGroupId = val.idGroupDataKlinik;
              this._timeZone = val.timeZone;
            }
          });
        }
      });
    }
  }

  /**
   * Get current clinic ID from the service.
   */
  public get clinicId() {
    if (!this._clinicId) return null;
    return this._clinicId;
  }

  public get clinicGroupId() {
    if (!this._clinicGroupId) return null;
    return this._clinicGroupId;
  }

  public get clinicTimeZone() {
    if (!this._clinicId) return null;
    return this._timeZone;
  }

  public async getClinicIdAsync(): Promise<number> {
    if (this._clinicId) return this._clinicId;

    const clinic = await this.clinicSet$.pipe(take(1), timeout(5000)).toPromise();
    return clinic.id;
  }

  public async setClinic(val: DataKlinik) {
    return this.storage.set(storageDict.klinik, val).then((item) => {
      this._clinicId = item.id;
      this._clinicGroupId = item.idGroupDataKlinik;
      this._timeZone = item.timeZone;
      this.clinicSet$.next(item);
    });
  }
}
