import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { ForegroundNotificationService } from './foreground-notification.service';
import * as firebase from 'firebase/app';
import { EventEmitter } from 'events';
import { StorageService } from './storage/storage.service';
import { storageDict } from './storage/storage-dict';
import { Observable, of, Subject } from 'rxjs';
import { NotificationMessage } from '../models/fcm.model';
import * as moment from 'moment';
import { FcmTopicsService } from './fcm-topics.service';
import {
  PushNotifications,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/push-notifications';
import { AlertService } from './alert-service';

// called from renderer-epr.js to get FCM token for electron.
declare function getEPRToken(): string;
declare const eprEvent: EventEmitter;
@Injectable({
  providedIn: 'root',
})
export class FcmService {
  /** Sends an observable when a message/notification arrives */
  private message$ = new Subject<NotificationMessage>();

  private eprListenerAttached = false;
  private pwaListenerAttached = false;

  constructor(
    private afMessaging: AngularFireMessaging,
    private fcmTopicService: FcmTopicsService,
    // private firebaseX: FirebaseX,
    private platform: Platform,
    private storage: StorageService,
    private router: Router,
    private foregroundNotificationService: ForegroundNotificationService,
    private alertService: AlertService
  ) {
    this.message$.subscribe((res) => {
      /** URLs where notifications will not be shown on foreground. */
      const urlFilter = ['appointment-chat-rooms', 'chat-rooms'];
      if (!new RegExp(urlFilter.join('|')).test(this.router.url.toLowerCase())) {
        if (res.type === 'notification') {
          this.foregroundNotificationService.showNotification(
            res.notification.title,
            res.notification.body,
            res.redirect,
            3000
          );

          if (res.redirect && res.data?.autoRedirect === 'true') {
            this.router.navigateByUrl(res.redirect, { replaceUrl: true });
          }
        }
      }
    });
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        PushNotifications.requestPermissions().then((res) => {
          if (res) {
            PushNotifications.register();
          }
        });
      }
    });
  }

  /** Returns an observable which gives payload on message/notification recevied. */
  public messageReceived(): Observable<NotificationMessage> {
    return this.message$.asObservable();
  }

  /** Fetch token from firebase, and store it into local storage. */
  public async fetchAndStoreToken() {
    if (this.platform.is('capacitor')) {
      // Android/iOS app
      PushNotifications.addListener('registration', (token) => {
        console.log(token.value);
        this.storage.setItem('fcm_token', token.value).toPromise();
      });
    } else if (this.platform.is('electron')) {
      // Electron App
      const eprToken = getEPRToken();
      await this.storage.setItem('fcm_token', eprToken).toPromise();
      eprEvent.on('token_update', async (token) => {
        await this.storage.setItem('fcm_token', token).toPromise();
      });
    } else {
      // Browser, and PWA
      this.afMessaging.requestToken
        .pipe(
          // tap((token) => console.log(token)),
          switchMap((token) => this.storage.setItem(storageDict.fcmToken, token))
        )
        .subscribe();
    }
  }

  public attachListener() {
    if (this.platform.is('capacitor')) {
      // const topics = [
      //   'chat-now',
      // ];
      // topics.forEach((topic) => this.fcmTopicService.initTopicSubscription(topic));

      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log(notification);
        const notifMsg = new NotificationMessage(
          notification.title ? 'notification' : 'data',
          notification.data,
          { title: notification.title, body: notification.body },
          notification.data.redirect,
          moment().toDate()
        );
        this.message$.next(notifMsg);
      });
      // this.firebaseX.onMessageReceived().pipe(
      //   tap((msg) => {
      //     if (msg.tap === 'background') {
      //       return this.router.navigateByUrl(msg.redirect || '/');
      //     }
      //   }),
      //   map((msg) => {
      //     if (msg.tap !== 'background') {
      //       return new NotificationMessage(
      //         msg.messageType,
      //         {...msg},
      //         (msg.messageType === 'notification') ? {title: msg.title, body: msg.body} : {},
      //         msg.redirect,
      //         moment(parseInt(msg.sent_time)).toDate(),
      //       );
      //     } else {
      //       return null;
      //     }
      //   })
      // ).subscribe((msg) => {
      //   if (msg !== null) this.message$.next(msg);
      // });
    } else if (this.platform.is('electron')) {
      if (!this.eprListenerAttached) {
        eprEvent.on('notification', async (res) => {
          // this.onMessageReceived(res.notification);
        });
        this.eprListenerAttached = true;
        // console.debug('[FCM] Electron Listener Attached')
      }
    } else {
      if (!this.pwaListenerAttached) {
        this.afMessaging.messages.subscribe((payload: any) => {
          // console.log(payload);
          const payloadMsg = new NotificationMessage(
            payload.notification ? 'notification' : 'data',
            { ...payload.data },
            { ...payload.notification },
            payload.data?.redirect,
            moment().toDate()
          );
          this.message$.next(payloadMsg);
        });
        this.pwaListenerAttached = true;
      }
    }
  }

  /** Get current token stored in local storage. */
  public async getTokenFromStorage() {
    return await this.storage.getItem('fcm_token').toPromise();
  }

  public requestPermission(): Observable<any> {
    if (!this.platform.is('capacitor')) {
      return this.afMessaging.requestPermission;
    } else {
      return of(true);
    }
  }
}
