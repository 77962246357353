import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoCallApiService } from '../api/video-call.api.service';

@Injectable({
  providedIn: 'root',
})
export class VideoCallDataService {
  constructor(private videoCallApiService: VideoCallApiService) {}

  public getAccessToken(roomName: string): Observable<{ token: string; roomName: string }> {
    return this.videoCallApiService.getAccessToken(roomName);
  }
}
