import { FormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TimeoutInterceptor } from './interceptors/timeout-interceptor';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { TimezoneInterceptor } from './interceptors/timezone-interceptor';
import { IonicStorageModule } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { AuthGuard } from './guards/auth.guard';
import { PusherProvider } from './providers/pusher.provider';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
// import { FcmService } from './services/fcm.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebcamModule } from 'ngx-webcam';
import { BrowserModule } from '@angular/platform-browser';
import { GlobalModule } from './components/global/global.module';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ClientErrorHandler } from './services/client-error-handler.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { NgSelectModule } from '@ng-select/ng-select';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { BackgroundGeolocation } from '@ionic-native/background-geolocation';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    WebcamModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      backButtonIcon: 'arrow-back',
      backButtonText: '',
    }),
    AngularFireMessagingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
    }),
    GlobalModule,
    NgSelectModule,
    CKEditorModule,
  ],
  providers: [
    AndroidPermissions,
    AuthGuard,
    Calendar,
    Device,
    PusherProvider,
    // FcmService,
    Geolocation,
    BarcodeScanner,
    // BackgroundGeolocation,
    EmailComposer,
    BluetoothSerial,
    { provide: ErrorHandler, useClass: ClientErrorHandler },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
