<div class="popover-root" #popOverRoot [ngStyle]="
  {
    'left': position.x + 'px',
    'top': position.y + 'px',
    'transition' : (isReorientating) ? 'all 0.2s ease-out' : ''
  }">
  <div class="top-actions">
    <ion-button (click)="resumeCall()" fill="clear">
      <ion-icon slot="icon-only" name="expand" size="large"></ion-icon>
    </ion-button>
  </div>
  <div class="video-call">
    <div #remoteMedia class="main-media"></div>
  </div>
  <div class="actions">
    <!-- <ion-button fill="clear" (click)="switchCamera()">
      <ion-icon slot="icon-only" name="camera-reverse" size="large"></ion-icon>
    </ion-button> -->
    <ion-button color="secondary" (click)="showLeaveAlert()">
      <ion-icon slot="icon-only" name="call" size="large"></ion-icon>
    </ion-button>
    <ion-button fill="clear" (click)="toggleMute()">
      <ion-icon slot="icon-only" [name]="(micMute) ? 'mic-off' : 'mic'" size="large"></ion-icon>
    </ion-button>
  </div>
</div>